import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Koda from "./pages/Koda";
import Resource from "./pages/Resource";
import Environment from './pages/Environment';
import Artifact from './pages/Artifact';
import Land from './pages/Land';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<App />} />
        <Route path="/:page" element={<App />} />  {/* optional uri param */}
        
        <Route path="/land" element={<Land />} />
        <Route path="/land/:id" element={<Land />} />  {/* optional uri param */}

        <Route path="/koda" element={<Koda />} />
        <Route path="/koda/:id" element={<Koda />} />  {/* optional uri param */}

        <Route path="/environment" element={<Environment />} />
        <Route path="/environment/:name" element={<Environment />} />  {/* optional uri param */}

        <Route path="/resource" element={<Resource />} />
        <Route path="/resource/:name" element={<Resource />} />  {/* optional uri param */}

        <Route path="/artifact" element={<Artifact />} />
        <Route path="/artifact/:name" element={<Artifact />} />  {/* optional uri param */}
        {/* TODO: <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>

    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
