import '../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams, Link } from "react-router-dom";

const Environment = () => {

  // get URI params (:name)
  const params = useParams();

  const [searchId, setSearchId] = useState("steppes");
  const [data, setData] = useState();

  // get environment with name and set local data
  const getData = (id) => {
    const target = 'https://otherdex2.herokuapp.com/api/environment/' + id;
    // console.log(target);
    axios.get(target)
      .then((res) => {
        // console.log(res.data);
        setData(JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // on load search from URI name
  useEffect(() => {
    if (params && params.name) {
      setSearchId(params.name);
      getData(params.name);
    } else {
      getData(searchId);
    }
  }, [params]);

  return (
    <div className="App">
      <header className="App-header">
        <h3>Environment</h3>

        <div style={{width: '90%',  wordWrap: 'break-word'}}>
          {data}
        </div>
        

        <label>
          <input type="text" value={searchId} onChange={(e) => { setSearchId(e.target.value) }} />
        </label>
        {/* <button type="button" onClick={() => {getData(searchId)}}>Search</button> */}
        <Link to={"/environment/"+searchId} className="search-button">Search</Link>

      </header>
    </div>
  );
}

export default Environment;
