import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Icon, ImageListItem } from '@mui/material/';
import openseaBlue from "../assets/opensea-blue.png";
import looksrareGreen from "../assets/looksrare-green.png";
import '../App.css';

// Small browse Otherside card
const OtherCard = ({ info }) => {

    const [imgLoaded, setImgLoaded] = useState(false);
    const [resources, setResources] = useState([]);
    const [resTiers, setResTiers] = useState([]);
    const [environment, setEnvironment] = useState(null);
    const [sediment, setSediment] = useState(null);

    // handle react router uri
    const navigate = useNavigate();
    const handleRouter = useCallback((deedId) => navigate("/land/" + deedId, { replace: true, state: { price: info.price, market: info.market }  }), [navigate]);

    useEffect(() => {
        setResources(info.resources);
        setResTiers(info.resources_tiers);
        setEnvironment(info.environment);
        setSediment(info.sediment);
    }, []);

    return (
        <>
            <ImageListItem key={info.id} style={{ margin: '10px' }} className="browse-img">
                {imgLoaded ?
                    <div style={{ background: '#000', fontSize: '10px', padding: '7px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {environment ?
                                <div>
                                    <span>{environment['name']}</span>
                                    <br />
                                    <span>{[...Array(environment['tier'])].map((e, i) => "*")}</span>
                                </div> : null}
                            {sediment ?
                                <div>
                                    <span>{sediment['name']}</span>
                                    <br />
                                    <span>{[...Array(sediment['tier'])].map((e, i) => "*")}</span>
                                </div> : null}
                        </div>
                        <hr style={{ width: '85%' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {resources.map((res, index) => {
                                return (
                                    <div key={index}>
                                        <span>{resources[index]}</span>
                                        <br />
                                        <span>{[...Array(resTiers[index])].map((e, i) => "*")}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : null}
                <img
                    src={`${info.image}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${info.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={info.id}
                    style={imgLoaded ? {} : { display: 'none' }}
                    onLoad={() => setImgLoaded(true)}
                    // onClick={() => window.open("/land/" + info.id, "_self")}
                    onClick={() => handleRouter(info.id)}
                />
                {imgLoaded ?
                    <div className="browse-img-info">
                        {info.price ?
                            <a
                                target="_blank" rel="noreferrer"
                                href={"https://looksrare.org/collections/0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258/" + info.id}
                                style={{ position: 'absolute', left: '10px', display: 'flex', textDecoration: 'none', color: '#fff' }}
                            >
                                <span>{info.price + " ETH"}</span>
                            </a>
                            :
                            null}
                        <Link to={"/land/" + info.id} style={{ textDecoration: 'none', color: '#fff' }} state={{ price: info.price, marketplace: info.marketplace }} >
                            {"#" + info.id}
                        </Link>
                        <div style={{ position: 'absolute', right: '10px', display: 'flex' }}>
                            <a target="_blank" rel="noreferrer" href={"https://looksrare.org/collections/0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258/" + info.id}>
                                <Icon sx={{ display: 'flex' }} >
                                    <img alt="looksrare" src={looksrareGreen} className="browse-img-info-logo" />
                                </Icon>
                            </a>
                            <a target="_blank" rel="noreferrer" href={"https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/" + info.id}>
                                <Icon sx={{ display: 'flex', marginLeft: 1 }} >
                                    <img alt="opensea" src={openseaBlue} className="browse-img-info-logo" />
                                </Icon>
                            </a>
                        </div>
                    </div>
                    :
                    null
                }
            </ImageListItem>
        </>
    )

}

export default OtherCard;