import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useCallback } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem, Icon } from '@mui/material/';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import MenuIcon from '@mui/icons-material/Menu';
// import AdbIcon from '@mui/icons-material/Adb';

import ape from "../assets/mutant.png";
import danceKoda from "../assets/koda-dance.gif";

import { styled, alpha, makeStyles } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const name = "Ligma"; // Brand
const pages = ['Home', 'Land']; // pages
const mobilePages = ['Home', 'Land', 'Koda', 'Resource', 'Artifact', 'Environment']; // mobile pages
const explorePages = ['Koda', 'Resource', 'Artifact', 'Environment']; // explore subpages
const settings = ['Profile', 'Account', 'Dashboard', 'Logout']; // profile

// Search bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  }
}));

const ProfileMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: "#8d97a5"
  }
});

// EXPLORE SUBPAGE MENU
const StyledExploreMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: "#8d97a5",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#fff',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#fff',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


// Navbar + Footer
const Layout = () => {
  const [anchorElNav, setAnchorElNav] = useState();
  const [anchorElUser, setAnchorElUser] = useState();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const routeLandPage = useCallback((landID) => navigate('/land/' + landID, { replace: true }), [navigate]);
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (Number.isInteger(parseInt(e.target.value)) && e.target.value >= 0 && e.target.value < 100000) {
        routeLandPage(e.target.value);
      }
      e.preventDefault();
    }
  }

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: '#121417' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 3 }} >
              <img alt="koda" src={danceKoda} height={45} width={45} style={{ position: 'absolute', marginTop: '-15px' }} />
            </Icon>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {name}
            </Typography>

            {/* MOBILE LAYOUT */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {mobilePages.map((page) => (
                  <div key={page}>
                    {/* Mobile Router: */}
                    {(page === "Home") ?
                      <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      </Link>
                      :
                      <Link to={"/" + page} style={{ textDecoration: 'none', color: '#000' }}>
                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      </Link>
                    }
                  </div>
                ))}
              </Menu>
            </Box>
            <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 3 }} >
              <img alt="koda" src={danceKoda} height={45} width={45} style={{ position: 'absolute', marginTop: '-15px' }} />
            </Icon>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {name}
            </Typography>

            {/* NORMAL LAYOUT */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <span style={{ marginLeft: '1.5rem', marginRight: '1.5rem', height: '30px', alignSelf: 'center', borderLeft: '2px solid #fff' }}></span>
              {pages.map((page) => (
                <div key={page}>
                  {/* Normal Router: */}
                  {(page === "Home") ?
                    <>
                      <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                        <Button
                          key={page}
                          onClick={handleCloseNavMenu}
                          sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                          {page}
                        </Button>
                      </Link>
                    </>
                    :
                    <Link to={"/" + page} style={{ textDecoration: 'none', color: '#fff' }}>
                      <Button
                        key={page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                      >
                        {page}
                      </Button>
                    </Link>
                  }

                </div>
              ))}

              {/* EXPLORE SUBPAGES */}
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  height: '35px', marginTop: 'auto', marginBottom: 'auto', background: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255, 0.02)'
                  },
                }}
              >
                Explore
              </Button>
              <StyledExploreMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="test"
              >
                {explorePages.map((page) => (
                  <Link to={"/" + page} style={{ textDecoration: 'none', color: '#000' }} key={page} >
                    <MenuItem onClick={handleClose} disableRipple>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </StyledExploreMenu>
            </Box>

            {/* SEARCH BAR */}
            <Search sx={{ display: { xs: 'none', md: 'flex' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Otherdeed #"
                inputProps={{ 'aria-label': 'search' }}
                onKeyPress={onKeyPress}
              />
            </Search>

            {/* SETTINGS PROFILE */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="ape" src={ape} />
                </IconButton>
              </Tooltip>
              <ProfileMenu
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu} key={setting} >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </ProfileMenu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
      <div className='footer'>
        Made with <span style={{ color: '#e25555', margin: '5px' }}>♥</span> by an ape
      </div>
    </>
  );
};

export default Layout;
