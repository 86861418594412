import '../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams, Link } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import { Box } from "@mui/material";

const Resource = () => {

  // get URI params (:name)
  const params = useParams();

  const [searchId, setSearchId] = useState("void");
  const [data, setData] = useState();
  const [resourceName, setResourceName] = useState("Void");

  // get resource with name and set local data
  const getData = (id) => {
    const target = 'https://otherdex2.herokuapp.com/api/resource/' + id;
    // console.log(target);
    axios.get(target)
      .then((res) => {
        // console.log(res.data);
        return res.data;
      })
      .then((res) => {
        setData(res[Object.keys(res)[0]]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // on load search from URI name
  useEffect(() => {
    if (params && params.name) {
      setSearchId(params.name);
      getData(params.name);
      setResourceName(params.name);
    } else {
      getData(searchId);
    }
  }, [params]);

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  }

  return (
    <div className="App">
      <header className="App-header">
        <h3>Resource</h3>
        <h5 style={{ marginTop: '0px' }}>{capitalize(resourceName)}</h5>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', fontSize: '20px', width: '60%', minWidth: '350px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StarIcon />
            <br />
            <img alt={resourceName + "-tier-1"} src={require("../assets/resources/" + capitalize(resourceName) + "-tier-1.png")} width={100} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              <StarIcon /><StarIcon />
            </div>
            <br />
            <img alt={resourceName + "-tier-2"} src={require("../assets/resources/" + capitalize(resourceName) + "-tier-2.png")} width={100} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              <StarIcon /><StarIcon /><StarIcon />
            </div>
            <br />
            <img alt={resourceName + "-tier-3"} src={require("../assets/resources/" + capitalize(resourceName) + "-tier-3.png")} width={100} />
          </div>
        </div>

        <br />

        {/* <div>
          <Box
            className="otherdeed-trait" style={{ height: '150px' }}
            sx={{
              width: { md: '250px' },
              marginBottom: { xs: '10px' }
            }}
          >
            <span style={{ fontSize: '2rem' }}>Northern</span>
            <hr />
            <span style={{ fontSize: '1rem' }}>{data[resource]}</span>
          </Box>
        </div> */}
        {data ?
          <>
            <Box
              style={{ width: '50%', justifyContent: 'space-evenly' }}
              sx={{
                display: { xs: 'block', md: 'flex' },
                width: { xs: '90%', md: '50%' }
              }}
            >
              {/* {JSON.stringify(data)} */}
              {Object.keys(data).map((item, _) => {
                if (item !== "total") {
                  return (
                    // <div>
                    //   <span>{item}</span>
                    //   <span>{JSON.stringify(data[item])}</span>
                    // </div>
                    <Box
                      className="otherdeed-trait"
                      style={{ height: '200px' }}
                      sx={{
                        width: { md: '250px' },
                        marginBottom: { xs: '10px' }
                      }}
                    >
                      {/* {JSON.stringify(data.attributes[item])} */}
                      <span style={{ fontSize: '2rem', marginTop: '-10px' }}>{item}</span>
                      <hr />
                      {/* <span style={{ fontSize: '1rem' }}>{JSON.stringify(data[item])}</span> */}
                      <div style={{ fontSize: '1rem', display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                        <div>
                          <span>*</span><br />
                          <span>{data[item]["1"]}</span>
                        </div>
                        <div>
                          <span>**</span><br />
                          <span>{data[item]["2"]}</span>
                        </div>
                        <div>
                          <span>***</span><br />
                          <span>{data[item]["3"]}</span>
                        </div>
                      </div>
                      <hr />
                      <span style={{ fontSize: '1.5rem' }}>{data[item]["1"] + data[item]["2"] + data[item]["3"]}</span>
                    </Box>
                  )
                }
              })}
            </Box>
            <br />
            <Box
              className="otherdeed-trait"
              style={{ height: '120px' }}
              sx={{
                width: { xs: '140px', md: '250px' },
                marginBottom: { xs: '10px' }
              }}
            >
              <span style={{ marginTop: '-10px', fontSize: '2rem' }}>Total</span>
              <hr />
              <span style={{ fontSize: '1.5rem' }}>{JSON.stringify(data[Object.keys(data)[4]])}</span>
            </Box>
            <br />
          </>
          :
          null}



        <label>
          <input type="text" value={searchId} onChange={(e) => { setSearchId(e.target.value) }} />
        </label>
        {/* <button type="button" onClick={() => {getData(searchId)}}>Search</button> */}
        <Link to={"/resource/" + searchId} className="search-button">Search</Link>

      </header>
    </div>
  );
}

export default Resource;
