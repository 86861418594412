import '../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams, Link } from "react-router-dom";
import { Box } from "@mui/material";

const Artifact = () => {

  // get URI params (:name)
  const params = useParams();

  const [searchName, setSearchName] = useState("Jar of Koda Farts");
  const [data, setData] = useState();
  const [artifact, setArtifact] = useState("Jar of Koda Farts");

  // get environment with name and set local data
  const getData = (name) => {
    const target = 'https://otherdex2.herokuapp.com/api/artifact/' + name;
    // console.log(target);
    axios.get(target)
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => {
        if (word !== "of" && word !== "from" && word !== "a") {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(' ');
  };

  // on load search from URI name
  useEffect(() => {
    if (params && params.name) {
      setSearchName(params.name);
      getData(params.name);
      setArtifact(toTitleCase(params.name));
    } else {
      getData(searchName);
    }
  }, [params]);

  return (
    <div className="App">
      <header className="App-header">
        <h3>Artifact</h3>
        <span>{artifact}</span>
        <br />
        <img alt={artifact} src={require("../assets/artifacts/" + toTitleCase(artifact) + ".png")} width={150} />
        <br />
        {data ?
          <Box
            className="otherdeed-trait" style={{ height: '120px' }}
            sx={{
              width: { md: '250px' },
              marginBottom: { xs: '15px' }
            }}
          >
            {/* {JSON.stringify(data.attributes[item])} */}
            <span style={{ fontSize: '2rem' }}>Total</span>
            <hr />
            <span style={{ fontSize: '1.5rem', marginBottom: '10px' }}>{data[artifact]}</span>
          </Box> : null}

        {/* <div style={{width: '90%',  wordWrap: 'break-word'}}>
          {data}
        </div> */}


        <label>
          <input type="text" value={searchName} onChange={(e) => { setSearchName(e.target.value) }} />
        </label>
        {/* <button type="button" onClick={() => {getData(searchId)}}>Search</button> */}
        <Link to={"/artifact/" + searchName} className="search-button">Search</Link>

      </header>
    </div>
  );
}

export default Artifact;
