import '../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Otherdeed from "../components/Otherdeed";

import { useParams, Link } from "react-router-dom";

const Land = () => {

  // get URI params (:ID)
  let params = useParams();

  const [searchId, setSearchId] = useState(0);
  const [data, setData] = useState();

  // get otherdeed with ID and set local data
  const getData = (id) => {
    const target = 'https://otherdex2.herokuapp.com/api/land/' + id;
    // console.log(target);
    axios.get(target)
      .then((res) => {
        // console.log(res.data);
        setData(JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // on load search from URI ID
  useEffect(() => {
    if (params && params.id) {
      setSearchId(params.id);
      getData(params.id);
    } else {
      getData(searchId);
    }
  }, [params]);

  return (
    <div className="App">
      <header className="App-header">
        {/* <div style={{width: '90%',  wordWrap: 'break-word'}}>
          {data}
        </div> */}
        {data ?
          <Otherdeed data={data} />
          : null}


        <label>
          <input type="text" value={searchId} onChange={(e) => { setSearchId(e.target.value) }} />
        </label>
        {/* <button type="button" onClick={() => {getData(searchId)}}>Search</button> */}
        <div>

          {parseInt(searchId) > 0 ?
            <Link to={"/land/" + (parseInt(searchId) - 1)} className="pagination">{"<"}</Link>
            :
            <span style={{ margin: '10px', color: 'grey' }}>{"<"}</span>
          }
          <Link to={"/land/" + searchId} className="search-button">Search</Link>
          {parseInt(searchId) < 99999 ?
            <Link to={"/land/" + (parseInt(searchId) + 1)} className="pagination">{">"}</Link>
            :
            <span style={{ margin: '10px', color: 'grey' }}>{">"}</span>
          }

        </div>
        <br /><br />
      </header>
    </div>
  );
}

export default Land;
