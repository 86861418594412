import './App.css';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

import { ImageList } from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import OtherCard from "./components/OtherCard";

// BROWSE PAGE
const App = () => {

  // get URI params (:page)
  let params = useParams();

  // Material UI Responsive css
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const [searchId, setSearchId] = useState(0);
  const [offset, setOffset] = useState(16);
  const [page, setPage] = useState(1);

  const [sale, setSale] = useState(true);
  const [cursor, setCursor] = useState(null);

  const [imageList, setImageList] = useState([]);

  const [alignment, setAlignment] = useState('Sale');

  // change tab (ie, browse by ID or Sale)
  const handleToggleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setImageList([]);
    if (event.target.value === "ID") {
      setCursor(null);
      getData(searchId);
    } else if (event.target.value === "Sale") {
      handleRouterHome();
      getListings();
    }
  };

  // helper for parse for resources and sort array
  const getResources = (data) => {
    let resources = [];
    let resourcesTier = [];
    let environment = {};
    let sediment = {};
    for (const attr in data) {
      const curr = data[attr];
      if (curr.trait_type.includes('Resource') && !curr.trait_type.includes('Tier')) {
        resources.push(curr.value);
      } else if (curr.trait_type.includes('Resource Tier')) {
        resourcesTier.push(curr.value);
      } else if (curr.trait_type.includes('Environment') && !curr.trait_type.includes('Tier')) {
        environment['name'] = curr.value;
      } else if (curr.trait_type.includes('Environment Tier')) {
        environment['tier'] = curr.value;
      } else if (curr.trait_type.includes('Sediment') && !curr.trait_type.includes('Tier')) {
        sediment['name'] = curr.value;
      } else if (curr.trait_type.includes('Sediment Tier')) {
        sediment['tier'] = curr.value;
      }
    }
    return {
      resources: resources,
      resourcesTier: resourcesTier,
      environment: environment,
      sediment: sediment
    };
  }

  // parse for resources and sort array
  const filterTraits = (data) => {
    let resList = [];
    data.forEach((item) => {
      const img = item.image;
      const deed = item.id;
      const price = item.price || null;
      const market = item.market || null;
      const attr = getResources(item.attributes);

      let obj = {};
      obj["id"] = deed;
      obj["image"] = img;
      obj["price"] = price;
      obj["market"] = market;
      obj["resources"] = attr.resources;
      obj["resources_tiers"] = attr.resourcesTier;
      obj["environment"] = attr.environment;
      obj["sediment"] = attr.sediment;
      resList.push(obj);
    });
    return resList;
  }

  // get a list of otherside starting from INDEX and size of OFFSET
  const getData = (id) => {
    const target = 'https://otherdex2.herokuapp.com/api/lands/' + id + '/' + offset + '/'; // 16 for default offset
    axios.get(target)
      .then((res) => {
        // console.log(res.data);
        let resList = filterTraits(res.data);
        setImageList(resList);
        setSale(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // conversion of WEI units to ETH 
  const weiToEth = (wei) => {
    const multiplier = Math.pow(10, 4);
    const eth = wei / Math.pow(10, 18);
    return Math.round(eth * multiplier) / multiplier;
  }

  // filter listings from response data
  const filterListings = (listings) => {
    return new Promise((resolve, reject) => {
      try {
        const data = listings.data.data;
        let ids = "";
        let retList = [];
        data.map((i, index) => {
          let obj = {};
          obj['id'] = parseInt(i.tokenId);
          ids += i.tokenId + ",";
          obj['price'] = weiToEth(i.price);
          obj['market'] = "looksrare";
          retList.push(obj);
          if (index === data.length - 1) { // if last element
            // console.log(i.hash);
            setCursor(i.hash);
          }
        });
        resolve([ids, retList]);
      } catch (err) {
        reject(err);
      }
    })
  }

  // get listings data from Looksrare
  const getListings = async () => {
    setImageList([]);
    const looksrare = 'https://api.looksrare.org/api/v1/orders?isOrderAsk=true&collection=0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258&status%5B%5D=VALID&pagination%5Bfirst%5D=16&sort=PRICE_ASC';
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    const listings = await axios.get(looksrare, options);
    const [ids, filteredListings] = await filterListings(listings);
    // console.log(filteredListings);
    axios.get('https://otherdex2.herokuapp.com/api/listoflands/' + ids)
      .then((res) => {
        // console.log(res.data);
        const mergeListings = filteredListings.map((item, i) => Object.assign({}, item, res.data[i]));
        // console.log(filterTraits(mergeListings));
        setImageList(filterTraits(mergeListings));
        setSale(true);
      })
      .catch((err) => console.log(err))
  }

  const loadMoreListings = async (event) => {
    event.preventDefault();
    const looksrare = "https://api.looksrare.org/api/v1/orders?isOrderAsk=true&collection=0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258&status%5B%5D=VALID&pagination%5Bfirst%5D=16&pagination%5Bcursor%5D=" + cursor + "&sort=PRICE_ASC";
    const options = { method: 'GET', headers: { accept: 'application/json' } };

    const listings = await axios.get(looksrare, options);
    const [ids, filteredListings] = await filterListings(listings);
    axios.get('https://otherdex2.herokuapp.com/api/listoflands/' + ids)
      .then((res) => {
        const mergeListings = filteredListings.map((item, i) => Object.assign({}, item, res.data[i]));
        const moreListings = [...imageList, ...filterTraits(mergeListings)]
        setImageList(moreListings);
        console.log(moreListings);
      })
      .catch((err) => console.log(err))
  }

  // get number of pages for browse by ID
  const getPagination = () => {
    return Math.ceil(100000 / offset);
  }

  // pagination for browse by ID
  const handlePageChange = (_, value) => {
    setPage(value);
    setSearchId(offset * (value - 1));
    handleRouter(value);
  };

  // handle react router uri
  const navigate = useNavigate();
  const handleRouter = useCallback((setPage) => navigate("/" + setPage, { replace: true }), [navigate]);
  const handleRouterHome = useCallback(() => navigate("/", { replace: true }), [navigate]);

  // on load display first page
  useEffect(() => {
    if (alignment === "ID") {
      getData(searchId);
    } else if (cursor === null && alignment === "Sale") {
      getListings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // on load search from URI page
  useEffect(() => {
    if (params && params.page && alignment === "ID") {
      setPage(parseInt(params.page));
      getData((params.page - 1) * offset);
    }
  }, [params]);

  return (
    <div className="App">
      <header className="App-header">
        <h3>Browse Otherdeeds</h3>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleToggleChange}
          aria-label="Platform"
        >
          <ToggleButton value="ID" style={{ color: '#fff' }}>ID</ToggleButton>
          <ToggleButton value="Sale" style={{ color: '#fff' }}>Sale</ToggleButton>
        </ToggleButtonGroup>

        {imageList.length >= offset ?
          <ImageList sx={{ width: '70%', height: '80%', paddingTop: '5px' }} cols={isMobile ? 4 : 1}>
            {imageList.map((item) => {
              return (
                <OtherCard info={item} key={item.id} />
              );
            })}
          </ImageList>
          :
          <Box sx={{ display: 'flex', margin: '20px' }}>
            <CircularProgress />
          </Box>
        }

        {sale ?
          <Button
            variant="outlined"
            style={{ backgroundColor: "#5a6472", color: '#fff', borderColor: '#fff' }}
            onClick={(event) => loadMoreListings(event)}
          >
            Load More
          </Button>
          :
          <Stack spacing={2} >
            <Pagination
              count={getPagination()}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              showFirstButton showLastButton
              sx={{ button: { color: '#fff' } }}
            />
          </Stack>}

        <br />

      </header>
    </div>
  );
}

export default App;
