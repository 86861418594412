import '../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from "@mui/material";

import { useParams, Link } from "react-router-dom";

const Koda = () => {

    // get URI params (:ID)
    const params = useParams();

    const [searchId, setSearchId] = useState("0");
    const [data, setData] = useState();

    // get resource with name and set local data
    const getData = (id) => {
        const target = 'https://otherdex2.herokuapp.com/api/koda/' + id;
        // console.log(target);
        axios.get(target)
            .then((res) => {
                // console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // on load search from URI name
    useEffect(() => {
        if (params && params.id) {
            setSearchId(params.id);
            getData(params.id);
        } else {
            getData(searchId);
        }
    }, [params]);

    return (
        <div className="App">
            <header className="App-header">
                <h3>{"Koda #" + searchId}</h3>

                {(data && data.attributes) ?
                    <>
                        <img alt={data.id} src={data.image} width={300} />
                        <br />
                        <Box
                            style={{ width: '50%', justifyContent: 'space-evenly' }}
                            sx={{
                                display: { xs: 'block', md: 'flex' },
                                width: { xs: '90%', md: '50%' }
                            }}
                        >
                            {
                                Object.keys(data.attributes).map((item, _) => {
                                    return (
                                        <Box
                                            className="otherdeed-trait" style={{ height: '120px' }}
                                            sx={{
                                                width: { md: '250px' },
                                                marginBottom: { xs: '10px' }
                                            }}
                                        >
                                            {/* {JSON.stringify(data.attributes[item])} */}
                                            <span style={{ fontSize: '2rem' }}>{data.attributes[item].trait_type}</span>
                                            <hr />
                                            <span style={{ fontSize: '1rem' }}>{data.attributes[item].value}</span>
                                        </Box>
                                        // <div className="otherdeed-trait" style={{ width: '40%' }}>
                                        //     <span>Sediment</span>
                                        //     <hr style={{ width: '100%' }} />
                                        //     <span>{getValueByKey(dataObj.attributes, 'Sediment')}</span>
                                        //     <span>{[...Array(getValueByKey(dataObj.attributes, 'Sediment Tier'))].map((e, i) => "*")}</span>
                                        // </div>
                                    );
                                })
                            }
                        </Box>
                    </>
                    : null}

                <br />
                <label>
                    <input type="text" value={searchId} onChange={(e) => { setSearchId(e.target.value) }} />
                </label>
                {/* <button type="button" onClick={() => {getData(searchId)}}>Search</button> */}
                <div>
                    {parseInt(searchId) > 0 ?
                        <Link to={"/koda/" + (parseInt(searchId) - 1)} className="pagination">{"<"}</Link>
                        :
                        <span style={{ margin: '10px', color: 'grey' }}>{"<"}</span>
                    }
                    <Link to={"/koda/" + searchId} className="search-button">Search</Link>
                    {parseInt(searchId) < 9999 ?
                        <Link to={"/koda/" + (parseInt(searchId) + 1)} className="pagination">{">"}</Link>
                        :
                        <span style={{ margin: '10px', color: 'grey' }}>{">"}</span>
                    }
                </div>

            </header>
        </div>
    );
}

export default Koda;
