import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import '../App.css';
import openseaBlue from "../assets/opensea-blue.png";
import looksrareGreen from "../assets/looksrare-green.png";
import axios from "axios";

// Otherdeed full data display info
const Otherdeed = ({ data }) => {

    const location = useLocation();
    const price = location.state?.price;
    const market = location.state?.market;

    const dataObj = JSON.parse(data);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [koda, setKoda] = useState({});

    // given object key name, find key value pair 
    const getValueByKey = (object, value) => {
        const obj = object.find(i => {
            return i.trait_type === value;
        })
        if (obj) {
            return obj.value;
        } else {
            return "None";
        }
    }

    const getKoda = (kodaId) => {
        axios.get('https://otherdex2.herokuapp.com/api/koda/' + kodaId)
            .then((res) => setKoda(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (getValueByKey(dataObj.attributes, 'Koda') !== "None" && Object.keys(koda).length === 0) {
            getKoda(getValueByKey(dataObj.attributes, 'Koda'));
        }
    }, [dataObj, koda]);

    return (
        <>
            <h3>
                Otherdeed # {dataObj.id}
            </h3>
            {price && market ?
                <>
                    <span>{price + " ETH"}</span>
                    {/* <span>{market}</span> */}
                    <br />
                </>
                : null
            }

            <div className="otherdeed-display" style={imgLoaded ? {} : { display: 'none' }}>
                <img
                    src={dataObj.image} alt={dataObj.id} width={400}
                    onLoad={() => setImgLoaded(true)}
                    className="deed-img"
                />
                <div className="otherdeed-trait-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="otherdeed-trait" style={{ width: '40%' }}>
                            <span>Sediment</span>
                            <hr style={{ width: '100%' }} />
                            <span>{getValueByKey(dataObj.attributes, 'Sediment')}</span>
                            <span>{[...Array(getValueByKey(dataObj.attributes, 'Sediment Tier'))].map((e, i) => "*")}</span>
                        </div>
                        <Link
                            to={"/environment/" + getValueByKey(dataObj.attributes, 'Environment')}
                            style={{ color: '#fff', textDecoration: 'none', width: '40%' }}
                            className="otherdeed-trait"
                        >
                            Environment
                            <hr style={{ width: '100%' }} />
                            <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Environment')}</span>
                            <span>{[...Array(getValueByKey(dataObj.attributes, 'Environment Tier'))].map((e, i) => "*")}</span>
                        </Link>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {
                            // NORTHERN RESOURCE
                            getValueByKey(dataObj.attributes, 'Northern Resource') !== "None" ?
                                <Link
                                    to={"/resource/" + getValueByKey(dataObj.attributes, 'Northern Resource')}
                                    style={{ color: '#fff', textDecoration: 'none', width: '20%', height: '180px' }}
                                    className="otherdeed-trait"
                                >
                                    <span>Southern</span>
                                    <hr />
                                    <img
                                        alt="Northern"
                                        src={require("../assets/resources/" + getValueByKey(dataObj.attributes, 'Northern Resource') + "-tier-" + getValueByKey(dataObj.attributes, 'Northern Resource Tier') + ".png")}
                                        height={75}
                                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    />
                                    <>
                                        <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Northern Resource')}</span>
                                        <span>{[...Array(getValueByKey(dataObj.attributes, 'Northern Resource Tier'))].map((e, i) => "*")}</span>
                                    </>
                                </Link>
                                :
                                <div className="otherdeed-trait" style={{ width: '20%', justifyContent: 'center' }}>
                                    <span style={{ marginTop: '-25px' }}>Northern</span>
                                    <hr />
                                    <span>None</span>
                                </div>
                        }
                        {
                            // SOUTHERN RESOURCE
                            getValueByKey(dataObj.attributes, 'Southern Resource') !== "None" ?
                                <Link
                                    to={"/resource/" + getValueByKey(dataObj.attributes, 'Southern Resource')}
                                    style={{ color: '#fff', textDecoration: 'none', width: '20%', height: '180px' }}
                                    className="otherdeed-trait"
                                >
                                    <span>Southern</span>
                                    <hr />
                                    <img
                                        alt="Southern"
                                        src={require("../assets/resources/" + getValueByKey(dataObj.attributes, 'Southern Resource') + "-tier-" + getValueByKey(dataObj.attributes, 'Southern Resource Tier') + ".png")}
                                        height={75}
                                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    />
                                    <>

                                        <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Southern Resource')}</span>
                                        <span>{[...Array(getValueByKey(dataObj.attributes, 'Southern Resource Tier'))].map((e, i) => "*")}</span>
                                    </>
                                </Link>
                                :
                                <div className="otherdeed-trait" style={{ width: '20%', justifyContent: 'center' }}>
                                    <span style={{ marginTop: '-25px' }}>Southern</span>
                                    <hr />
                                    <span>None</span>
                                </div>
                        }
                        {
                            // WESTERN RESOURCE
                            getValueByKey(dataObj.attributes, 'Western Resource') !== "None" ?
                                <Link
                                    to={"/resource/" + getValueByKey(dataObj.attributes, 'Western Resource')}
                                    style={{ color: '#fff', textDecoration: 'none', width: '20%', height: '180px' }}
                                    className="otherdeed-trait"
                                >
                                    <span>Southern</span>
                                    <hr />
                                    <img
                                        alt="Western"
                                        src={require("../assets/resources/" + getValueByKey(dataObj.attributes, 'Western Resource') + "-tier-" + getValueByKey(dataObj.attributes, 'Western Resource Tier') + ".png")}
                                        height={75}
                                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    />
                                    <>
                                        <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Western Resource')}</span>
                                        <span>{[...Array(getValueByKey(dataObj.attributes, 'Western Resource Tier'))].map((e, i) => "*")}</span>
                                    </>
                                </Link>
                                :
                                <div className="otherdeed-trait" style={{ width: '20%', justifyContent: 'center' }}>
                                    <span style={{ marginTop: '-25px' }}>Western</span>
                                    <hr />
                                    <span>None</span>
                                </div>
                        }
                        {
                            // EASTERN RESOURCE
                            getValueByKey(dataObj.attributes, 'Eastern Resource') !== "None" ?
                                <Link
                                    to={"/resource/" + getValueByKey(dataObj.attributes, 'Eastern Resource')}
                                    style={{ color: '#fff', textDecoration: 'none', width: '20%', height: '180px' }}
                                    className="otherdeed-trait"
                                >
                                    <span>Southern</span>
                                    <hr />
                                    <img
                                        alt="Eastern"
                                        src={require("../assets/resources/" + getValueByKey(dataObj.attributes, 'Eastern Resource') + "-tier-" + getValueByKey(dataObj.attributes, 'Eastern Resource Tier') + ".png")}
                                        height={75}
                                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    />
                                    <>
                                        <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Eastern Resource')}</span>
                                        <span>{[...Array(getValueByKey(dataObj.attributes, 'Eastern Resource Tier'))].map((e, i) => "*")}</span>
                                    </>
                                </Link>
                                :
                                <div className="otherdeed-trait" style={{ width: '20%' }}>
                                    <span style={{ marginTop: '-25px' }}>Eastern</span>
                                    <hr />
                                    <span>None</span>
                                </div>
                        }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {getValueByKey(dataObj.attributes, 'Koda') !== "None" ?
                            <Link
                                to={"/koda/" + getValueByKey(dataObj.attributes, 'Koda')}
                                style={{ color: '#fff', textDecoration: 'none', width: '26.666%', height: '180px' }}
                                className="otherdeed-trait"
                            >
                                <span style={{ marginTop: '-20px' }}>Koda</span>
                                <hr />
                                <img
                                    alt="koda"
                                    src={koda['image']}
                                    height={75}
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                />
                                <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Koda')}</span>
                            </Link>
                            :
                            <div className="otherdeed-trait" style={{ width: '26.666%' }}>
                                <span style={{ marginTop: '-20px' }}>Koda</span>
                                <hr style={{ width: '100%', marginBottom: '15px' }} />
                                <span>None</span>
                            </div>
                        }
                        {getValueByKey(dataObj.attributes, 'Artifact') !== "None" ?
                            <Link
                                to={"/artifact/" + getValueByKey(dataObj.attributes, 'Artifact')}
                                style={{ color: '#fff', textDecoration: 'none', width: '26.666%', height: '180px' }}
                                className="otherdeed-trait"
                            >
                                <span style={{ marginTop: '-20px' }}>Artifact</span>
                                <hr />
                                <img
                                    alt="artifact"
                                    src={require("../assets/artifacts/" + getValueByKey(dataObj.attributes, 'Artifact') + ".png")}
                                    height={75}
                                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                />
                                <span style={{ color: 'grey' }}>{getValueByKey(dataObj.attributes, 'Artifact')}</span>
                            </Link>
                            :
                            <div className="otherdeed-trait" style={{ width: '26.666%' }}>
                                <span style={{ marginTop: '-20px' }}>Artifact</span>
                                <hr />
                                <span>None</span>
                            </div>
                        }
                        <div className="otherdeed-trait" style={{ width: '26.666%' }}>
                            <span style={{ marginTop: '-20px' }}>Obelisk Piece</span>
                            <hr style={{ width: '100%', marginBottom: '15px' }} />
                            <span>{getValueByKey(dataObj.attributes, 'Obelisk Piece')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {dataObj ?
                <div style={{ display: 'flex' }}>
                    <a target="_blank" rel="noreferrer" href={"https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/" + dataObj.id}>
                        <img width={40} alt="opensea" src={openseaBlue} className="browse-img-info-logo" />
                    </a>
                    <a target="_blank" rel="noreferrer" href={"https://looksrare.org/collections/0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258/" + dataObj.id}>
                        <img width={40} alt="looksrare" src={looksrareGreen} className="browse-img-info-logo" style={{ marginLeft: '15px' }} />
                    </a>
                </div>
                : null}
            <br />
        </>
    )

}

export default Otherdeed;